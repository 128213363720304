<template lang="pug">
div(
  v-if='data'
  data-cnstrc-product-detail
  :data-cnstrc-item-id='data.productLineSlug'
  :data-cnstrc-item-variation-id='data.skuSlug ? `${data.productLineSlug}_${data.skuSlug}` : undefined'
  :data-cnstrc-item-name='data.productLineName'
  :data-cnstrc-item-price='data.actualPrice?.min'
  class='p-3 md:bg-white lg:p-0 m-auto xl:max-w-[2560px]'
)
  div(class='grid grid-cols-1 lg:gap-x-4 lg:pl-4 lg:grid-cols-[minmax(0,1fr)_320px] xl:grid-cols-[minmax(0,1fr)_540px]')
    div(class='lg:order-last lg:border-l')
      Crumbs(v-if='$device.isSmall' :crumbs='data.crumbs' class='pb-2 mb-1')

      div(
        id='product-sticky-panel'
        class='lg:sticky lg:overflow-y-auto lg:overflow-x-hidden lg:transition-all lg:px-4'
        :style='stickyCssOffset'
      )
        //- Product header
        div(class='mb-2 mt-1 lg:mt-6 text-pretty')
          span(
            v-if='isRealtruckBrand(data.brand.name) && !data.name?.toLowerCase()?.startsWith("realtruck")'
            class='text-4xl text-black font-extrabold uppercase font-brand lg:leading-6'
          )
            | RealTruck
            |
          h1(data-testid='productTitle' class='text-4xl text-black inline uppercase font-brand leading-6')
            InlineHtml(:text='data.name')
            template(v-if='isProductPage && data.applicationH1')
              | &nbsp;
              span(class='block text-gray-dark text-sm font-sans font-normal leading-snug') {{ data.applicationH1 }}

        div(class='flex items-center justify-between')
          ProductPricing(
            v-if='!$sitewideConfig.config.nonTransactionalEnabled'
            :actual-price='data.actualPrice'
            :base-price='data.basePrice'
            :discount='data.discount'
            :has-m-a-p-violation='data.hasMAPViolation'
            :is-out-of-stock='data.isOutOfStock'
            :is-dealer-only='data.isDealerOnly'
          )
          ProductReviews(
            :average-rating='data.averageRating'
            :total-reviews='data.totalReviews'
            :show-average-rating='showAverageRating'
          )

        //- Mobile Gallery
        ProductGallery(v-if='$device.isSmall' :data='data' :has-customer-photos='hasCustomerPhotos' class='mt-6')

        div(v-show='showFinancing' class='mt-6')
          AffirmAsLowAs(
            v-if='isIntegrationEnabled("affirm") && data.actualPrice'
            :price='data.actualPrice.min'
            page-type='product'
            class='[&_.affirm-modal-trigger]:text-inherit [&_.affirm-modal-trigger]:text-small [&_.affirm-modal-trigger]:underline'
          )
          PaypalMessaging(
            v-if='isIntegrationEnabled("paypal") && data.actualPrice'
            class='pt-2'
            :price='data.actualPrice.min'
            page-type='product'
            :is-grayscale='true'
          )

        div
          ProductInfo(:data='data')
          ProductPromoImages(v-if='data.promoImages')
          ClientOnly
            ProductSmallGroups(v-if='showRightSplitters && ocStore.groupKeys.length > 0' :data='data')

    div
      //- Desktop Gallery
      div(v-if='$device.isDesktop')
        Crumbs(:crumbs='data.crumbs' class='pt-2 ml-4')
        ProductGallery(:data='data' class='mt-6' :has-customer-photos='hasCustomerPhotos' show-thumbs)

      template(v-if='!$sitewideConfig.config.nonTransactionalEnabled')
        h2(class='text-center font-bold mt-10 lg:ml-6') Why Shop At {{ $sitewideConfig.config.siteName }}?
        div(class='mt-14 mb-10 max-w-6xl mx-auto grid justify-items-center gap-4 grid-cols-2 md:grid-cols-4')
          div(class='flex flex-col items-center max-w-48')
            div(class='relative rounded-full bg-black w-10 h-10')
              img(
                src='/images/checkmarkThick.svg'
                alt='checkmark'
                class='w-[20px] absolute m-auto inset-0'
                width='20'
                height='20'
              )
            div(class='font-bold pt-3') Guaranteed Fit
            div(class='relative text-xs text-center') This product will fit your vehicle or your money back
              Tooltip(mode='click' class='content inline-block align-middle ml-1 -mt-0.5' :is-full-width='!$device.isDesktop')
                InfoIcon(class='cursor-pointer text-action fill-current focus:outline-none')
                template(#text)
                  span We guarantee this part will fit your vehicle or you can return it for free.
                  UniversalLink(link='/guaranteed-fit/' class='ml-1' :open-new-window='true') Learn more

          div(class='flex flex-col items-center max-w-48')
            div(class='relative w-10 h-10')
              img(src='/images/financing.svg' alt='financing' class='absolute bottom-0' width='44' height='32')
            div(class='font-bold pt-3') Financing
            div(class='relative text-xs text-center') Build your dream truck with flexible financing options
              Tooltip(mode='click' class='content inline-block align-middle ml-1 -mt-0.5' :is-full-width='!$device.isDesktop')
                InfoIcon(class='cursor-pointer text-action fill-current focus:outline-none')
                template(#text)
                  span Select Affirm or PayPal Pay Later as your payment option at checkout.
                  UniversalLink(link='/financing/' class='ml-1' :open-new-window='true') Learn more

          div(class='flex flex-col items-center max-w-48')
            div(class='relative w-10 h-10')
              img(src='/images/support.svg' alt='support' class='absolute bottom-0' width='42' height='32')
            div(class='font-bold pt-3') Expert Support
            div(class='relative text-xs text-center') Get help from our product specialists
              Tooltip(mode='click' class='content inline-block align-middle ml-1 -mt-0.5' :is-full-width='!$device.isDesktop')
                InfoIcon(class='cursor-pointer text-action fill-current focus:outline-none')
                template(#text)
                  div Let us help you find what you need.
                  UniversalLink(link='CHAT' class='mr-1 underline' :open-new-window='true') Live Chat
                  span |
                  UniversalLink(link='PHONE' class='ml-1' :open-new-window='true') {{ $sitewideConfig.config.phoneGeneral }}

          div(class='flex flex-col items-center max-w-48')
            div(class='relative w-10 h-10')
              img(src='/images/calendar30.svg' alt='30 days' class='absolute bottom-0' width='40' height='40')
            div(class='font-bold pt-3') 30 Day Returns
            div(class='relative text-xs text-center') Most items can be returned within 30 days.
              Tooltip(mode='click' class='content inline-block align-middle ml-1 -mt-0.5' :is-full-width='!$device.isDesktop')
                InfoIcon(class='cursor-pointer text-action fill-current focus:outline-none')
                template(#text)
                  span You can return all or part of your purchase for free within 30 days.
                  UniversalLink(link='/returns/' class='ml-1' :open-new-window='true') Learn more

      ClientOnly
        div(v-if='ocStore.groupKeys.length > 0 && !showRightSplitters' id='multiproducts-area' data-testid='productGroups')
          ProductGroup(
            v-for='(groupKey, index) in ocStore.groupKeys'
            :key='groupKey'
            :group-key='groupKey'
            :array-index='index'
            :qty-selector='data.qtySelector'
            :is-dealer-only='data.isDealerOnly'
          )

        div(v-else-if='ocStore.integration' id='multiproducts-area' ref='groups' data-testid='productGroups')
          LazyProductThirdParty(:integration='ocStore.integration' :brand-name='data.brand.name')

      div(v-if='data' class='md:bg-white')
        div(class='max-w-6xl m-auto mt-4')
          ProductExtraInfo(:data='data' :has-customer-photos='hasCustomerPhotos' class='mb-12')
          ConstructorABTest
            template(#original)
              //- RECOMMENDED/SIMILAR PRODUCTS
              CarouselProduct(
                v-if='hasRelatedProducts && $sitewideConfig.config.recommendedProductsEnabled'
                product-type='recommended'
              )
              CarouselProduct(
                v-if='$sitewideConfig.config.recommendedProductsEnabled'
                id='carousel-similar-bottom'
                product-type='similar'
              )
              //- RECENT PRODUCTS
              CarouselProduct(product-type='recent')

            template(#constructor)
              ConstructorRecommendations(pod-id='product-page-2' :item-id='data.productLineSlug')
              ConstructorRecommendations(pod-id='product-page-3' :item-id='data.productLineSlug')
              ConstructorRecommendations(pod-id='product-page-4' :item-id='data.productLineSlug')
</template>

<script setup lang="ts">
/**
 * Order Notes: /p/300-industries-led-grilles/
 * Scroll: /p/retraxpro-xr-tonneau-cover/#scrollTarget-install
 */
import InfoIcon from '@/assets/info.svg?component'
import mitt from 'mitt'

const route = useRoute()
const { $device, $sitewideConfig, $speedcurve, $yotpo } = useNuxtApp()
const { isIntegrationEnabled, isRealtruckBrand } = useUtils()
const { getImageUrl } = useUrls()
const { resolveTemplates } = useMetaTagTemplates()
const { translateTemplate } = useTemplateTranslator()
const { headerOffset } = useStickyOffset()

const productLineSlug = route.params.productLine.toString()
const skuSlug = route.params.skuSlug?.toString()
const isProductPage = computed(() => !!route.params.skuSlug)
const ocStore = useOrderControlStore()
const productStore = useProductStore()
const fitmentStore = useFitmentStore()
const recStore = useRecommendationsStore()
const emitter = mitt()
const hasCustomerPhotos = ref(false)

try {
  await productStore.init(productLineSlug, skuSlug)
} catch (error) {
  if (error?.statusCode === 404) throw createError({ statusCode: 404, fatal: import.meta.client })
  throw createError({ statusCode: 500, message: error?.message, fatal: true })
}

ocStore.reset()

const data = computed(() => ocStore.topProduct || productStore.data)
const showRightSplitters = ref(false)

onMounted(async () => {
  if (isIntegrationEnabled('yotpo') && data.value?.yotpoId) {
    hasCustomerPhotos.value = await $yotpo.hasCustomerPhotos(data.value.yotpoId)
  }

  showRightSplitters.value =
    $sitewideConfig.config.rightAlignedSplitterEnabled && data.value?.brand?.name === 'Husky Liners'
})

// Offset the sticky right col by the height of the header if it is sticky
const stickyCssOffset = computed(() => {
  if (!$device.value.isDesktop) return 'top: 0;'
  return headerOffset.value > 0
    ? `top: ${headerOffset.value}px; max-height: calc(100vh - ${headerOffset.value}px)`
    : `top: 0; max-height: 100vh;`
})

provide('showRightSplitters', showRightSplitters)

const showFinancing = computed(() => {
  return (
    !ocStore.hasOrderControlError &&
    !data.value.isDealerOnly &&
    !ocStore.hasSplitters &&
    data.value.actualPrice &&
    data.value.actualPrice.min
  )
})

provide('pageBus', {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
})

// if you're on a sku page, with a sku that fits and drill to a new sku, only update the url (don't reload the whole page)
watch(
  () => ocStore.topProduct?.skuSlug,
  (newSkuSlug) => {
    if (!isProductPage.value || ocStore.hasMismatchedProduct || !newSkuSlug || route.params.skuSlug === newSkuSlug)
      return

    const newUrl = route.fullPath.replace(route.params.skuSlug.toString(), newSkuSlug)
    history.replaceState(null, '', newUrl)
  }
)

onBeforeMount(() => {
  $speedcurve.track(skuSlug ? 'Product Page' : 'Product Line Page')
})

onMounted(async () => {
  await ocStore.init(productLineSlug, skuSlug)
})

definePageMeta({
  // don't re-init the whole page when we just update fitment in the url
  key(route) {
    const skuSlug = (route.params.skuSlug as string) || ''
    const productLineSlug = (route.params.productLine as string) || ''
    const z = route.query.z || ''

    return productLineSlug + skuSlug + z
  },

  validate: (route) => {
    return isValidUrl(route.path)
  },
})

if (data.value) {
  useServerHead({
    script: [{ type: 'application/ld+json', innerHTML: getSchema(data.value), tagPosition: 'bodyClose' }],
  })
}

const metaTemplates = resolveTemplates(isProductPage.value ? 'product' : 'productLine')
const metaVariables = {
  ...(isProductPage.value && { PRODUCT: data.value?.name }),
  ...(isProductPage.value && { SKU: data.value?.mpn || data.value?.sku }),
  ...(!isProductPage.value && { PRODUCT_LINE: data.value?.name }), // it's really weird we don't just have productLineName from the API vs a 'name' field that changes
  BRAND: data.value?.brand?.name || '',
}

const socialImageUrl = data.value?.gallery[0]
  ? getImageUrl(data.value.gallery[0].key, data.value.gallery[0].filename, 800, 600, 80, 'fff')
  : ''

const title = computed(() => translateTemplate(metaTemplates.title, metaVariables))
const description = translateTemplate(metaTemplates.description, metaVariables)
useMetaTags({
  title,
  description,
  isNoIndex: !isProductPage.value && !!fitmentStore.makeSlug,
  ...(socialImageUrl && { socialImageUrl }),
})

const hasRelatedProducts = computed(() => !!recStore.state.related)

const showAverageRating = computed(() => {
  return !(
    $device.value.isMobile &&
    data.value?.discount &&
    data.value?.actualPrice?.min != data.value.actualPrice?.max
  )
})

function webPageSchema() {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `https://${$sitewideConfig.domain}${route.path}`,
    headline: title,
    description,
    publisher: {
      '@type': 'Organization',
      name: $sitewideConfig.config.siteName,
    },
  }
}

useServerHead({
  script: [{ type: 'application/ld+json', innerHTML: webPageSchema(), tagPosition: 'bodyClose' }],
})

function getSchema(data: ProductLine) {
  const obj: any = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: data.name,
  }

  if (data.gallery[0]) obj.image = getImageUrl(data.gallery[0].key, data.gallery[0].filename)

  if (data.totalReviews > 0) {
    obj.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: data.averageRating,
      reviewCount: data.totalReviews,
    }
  }

  if (data.brand && data.brand.name) {
    obj.brand = {
      '@type': 'brand',
      name: data.brand.name,
      // "logo": ?
    }
  }

  if (!isProductPage.value) {
    if (!data.hasMAPViolation) {
      if (data.actualPrice && data.actualPrice?.min !== data.actualPrice?.max)
        obj.offers = {
          '@type': 'AggregateOffer',
          lowPrice: data.actualPrice.min / 100,
          highPrice: data.actualPrice.max / 100,
          priceCurrency: 'USD',
          itemCondition: 'NewCondition',
        }
      else if (data.actualPrice) {
        obj.offers = {
          '@type': 'Offer',
          price: data.actualPrice.min / 100,
          priceCurrency: 'USD',
          itemCondition: 'NewCondition',
        }
      }
    }
  } else {
    if (data.actualPrice) {
      obj.offers = {
        '@type': 'Offer',
        price: data.actualPrice.min / 100,
        priceCurrency: 'USD',
        itemCondition: 'NewCondition',
      }
    }
    obj.mpn = data.mpn || data.sku
    obj.sku = data.sku

    obj.url = `https://${$sitewideConfig.domain}${useRoute().path}`
  }

  if (obj.offers)
    obj.offers.availability = data.isOutOfStock ? 'http://schema.org/OutOfStock' : 'http://schema.org/InStock'

  return JSON.stringify(obj)
}
</script>
